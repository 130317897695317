<template>
  <div>
    <div class="relative">
      <GmapMap
        ref="mapRef"
        :center="location"
        :zoom="zoom"
        :options="{
          zoomControl,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: true,
          disableDefaultUi: false,
          draggable,
        }"
        class="gmap"
        @click="onClickAction"
      >
        <GmapMarker :icon="pin" :position="location" />
      </GmapMap>

      <slot />
    </div>

    <TransitionExpand>
      <p v-if="location.description && showLocation" class="font-bold mt-6 md:text-lg">
        الموقع : <span class="text-sm font-normal">{{ location.description }}</span>
      </p>
    </TransitionExpand>
  </div>
</template>

<script>
import TransitionExpand from '@/components/ExpandTransition.vue';

export default {
  name: 'StaticMap',
  components: {
    TransitionExpand,
  },
  props: {
    /**
     * Represents coordinates with latitude and longitude values.
     *
     * @typedef {Object} Coordinates
     * @property {number} lat - The latitude value.
     * @property {number} lng - The longitude value.
     */
    value: {
      type: Object,
      default: () => ({}),
    },
    zoomControl: {
      type: Boolean,
      default: true,
    },
    draggable: {
      type: Boolean,
      default: true,
    },
    zoom: {
      type: Number,
      default: 15,
    },
    showLocation: {
      type: Boolean,
      default: true,
    },
    isClicked: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      location: {},
      mapLoaded: false,
      cairoLocation: { lat: 30.0444, lng: 31.2357 },
      pin: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAeCAYAAADHJYVoAAAACXBIWXMAAC4jAAAuIwF4pT92AAAE70lEQVRIia3WW2gUVxzH8e85M3vN7iTbxMSqVUyj4iWou0geFDQgfSi2CgEbG9paklDwAoK3lxbal/pQxEKpUBFb8G5bFfFNS2KhWkOwKDTaoEbFWy4bq9nN3s+/DxltDAnG1h8MM8PM+TC3//+MYuwEgBXuEgUmAD4gA/QCl4DT7pIaDVBjwGuBreXKM2eZ7VBjFTFN+/CjSWO4bTJcLCRpzT+hR3IdwFfADy/CHeC7sLLq13srWOMpZZL2PjtJAeJuC3DfZDmci/NttpsBKRwBPgGejIY7wKmZ2r90d2A6C60gWQQbxYAU6C5kGTB5wtqmwvISVhZ5EbxK8UdhkHWpLjpN+hywEng8Ej86S/tXHwxWMVX7EIR7Jsu+xD1O84g+FNrvx6TTlCGsIEJjaLJ7Z4o7JkPD4HX+MukfgdXDH8dHYWXJ2aLZ0uNEpceJyX5/pbwGMr16ruzYtVMutLXJtc5OudDWJjt27ZTp1XOlFGS/v1J6nJj0OFE5WzRbwsoS950BEAK6tvlel7gTk24nKgf8lQLIpu3bJDk4KKMlOTgom7ZvE0AO+Cul24lK3InJdt8kAbpcl/fKlUeuhKrlQTgql0PVEgbZuHXLqOjIbNiyWcIgl93xV0LVUq48AtQDHKr3lEqvE5NeJyYbdEQmz5klmXRmXHg6nZbJc2bJBh15ZtR7SgU4pIEFNVYIBSSlwCnziHVNzXh93tErYER8Ph/rmpo5ZR6RlAIKqLFCAAs0UDZVD0E9JkdcKWoXLxkX/DS1i5cQV4oekwPA9co0YA19TDBg8mjbQ6Sk5KXwSEkJ2vYwYPIowC07SwOFDIIAxdrG5LL0xvtfCu+N92NyWYq1jQCZoTrOa6D7lskAMEF7mQicaW15KfxMawsT3fEAN4e8Hg20ny8kECCoNHW6lN1795BMJscFJ5NJdu/dQ50uJag0AvxeSAC0A6yMKFvaQ/PkQTgqHaH5Ug7S0Nw0rk+xoblJykE6QvPlQTgq7aF5ElG2AO8A+IGrG70V0u/E5KETk5OBGWKDNDQ1Sm9f36hob1+fNDQ1ig1yMjBDHjoxiTsxWe+tEKAD8D9tXHUBpX86Gqiixg4BcDGfYONgJ4kpk3i/oYHlS5cxoayM3r4+zp5r5dDBg4Tu3ueb4Exq7KE6OZ9PUJ+6TlpMHXB8eFfcW6l9jSeCsyhVFrZS/C0FjiQe8rN0cwcoABYwFahTFdSHJlLitt645Fk52Mktk9nDUF9/ruUGgda37OJF3wffJCcGhSKgNFkxPJI8KTEElCaibLxKkxKDINho1qZucDb/+CJQizvtWcPwHNByw2TWGKRouV1MGkMWwQB+ZeEoC6/SFBCybm2ElcWX2fsczsW7gbcZml8ZiQP0A1cvFhJrZuiAWmAFSbsTmwCG56e5YmVxPNfPZ+m7T7tg23BsJA7QCZjfCgO1tXYxk7SH3DPy3xQpzZ+FFM2pLlKYT4F9I88ZDQf4NYWZf8kkZ6/0RPChMcMOelAkMXycusltkzkObBwNGQsHONMtuVUPJFf2ridC3r16DfiVZnP6Dq35J9eAVYzx3/KiRIHEF/4pEndicjO8QOJOTD73TxFgAFj4X9Dh+dCLkmPBKkk5i+RYsEq8KAE++L/w03w9VXvlRHCmvKG9Aux6VTCAF2ixh664xd1/pZkG/OKux5V/ALWVtVIkyO9FAAAAAElFTkSuQmCC',
    };
  },
  watch: {
    /**
     * Watches changes to the 'location' property and emits 'update:value' event if both latitude and longitude are present.
     * If the map is loaded, calls 'onGenerateDescription' method.
     */
    location(value) {
      if (!value?.lat || !value?.lng) return;
      this.$emit('update:value', value);
      if (this.mapLoaded) {
        this.onGenerateDescription();
      }
    },
    /**
     * Watches changes to the 'value' property and updates the 'location' property with the new value.
     */
    value(value) {
      if (!value?.lat || !value?.lng) return;
      this.location = value;
    },
    /**
     * Watches changes to the 'mapLoaded' property and emits 'onMapLoad' event when it becomes true.
     * If 'location' has valid latitude and longitude and the map is loaded, calls 'onGenerateDescription' method.
     */
    mapLoaded(value) {
      if (!value) return;

      this.$emit('onMapLoad');

      if (this.location?.lat && this.location?.lng) {
        this.onGenerateDescription();
      }
    },
  },
  created() {
    if (!this.value?.lat || !this.value?.lng) {
      this.location = this.cairoLocation;
      return;
    }
    this.location = this.value;
  },
  mounted() {
    // On map load
    this.$refs.mapRef.$mapPromise.then(() => {
      this.mapLoaded = true;
    });
  },
  methods: {
    /**
     * Generates a description for the location based on its latitude and longitude.
     * Uses Google Maps Geocoding API to retrieve the formatted address.
     */
    onGenerateDescription() {
      if (!this.location?.lat && !this.location?.lng) return;

      const geocoder = new window.google.maps.Geocoder();
      const latlng = new window.google.maps.LatLng(this.location?.lat, this.location?.lng);

      geocoder.geocode({ latLng: latlng, language: 'ar' }, (results, status) => {
        if (status === window.google.maps.GeocoderStatus.OK) {
          if (results[0]) {
            this.$set(this.location, 'description', results[0].formatted_address);
          } else {
            // eslint-disable-next-line no-console
            console.log('No results found');
          }
        } else {
          // eslint-disable-next-line no-console
          console.log('Geocoder failed due to:', status);
        }
      });
    },
    /**
     * Sets the location object based on the latitude and longitude obtained from the click event.
     */
    onClickAction(e) {
      if (!this.draggable) return;

      this.location = {
        lat: e?.latLng?.lat(),
        lng: e?.latLng?.lng(),
      };
    },
  },
};
</script>

<style lang="postcss">
.gmap {
  @apply w-full;
  height: 350px;
}
</style>
