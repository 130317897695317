<template>
  <div class="MapSearch" @keyup="handleKeyup">
    <div class="relative">
      <TextInput
        id="search"
        ref="searchTextField"
        v-model="input"
        name="search"
        placeholder="البحث عن المنطقة"
        type="text"
        class="SearchInput"
      />

      <div v-if="result.length" class="absolute top-full left-0 bg-white w-full shadow-custom z-10 px-2 mt-2">
        <ul class="py-2">
          <li
            v-for="(address, index) in result"
            :key="index"
            class="py-1 px-2 [ flex items-center ] text-sm mb-1 hover:bg-primary-100 cursor-pointer"
            :class="{
              'bg-primary-100': index === currentSearchItem,
              'bg-white': index !== currentSearchItem,
            }"
            @click="handleOnItemSelect(index)"
          >
            <p v-dompurify-html="highlightText(address.description, input)" />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { debounce } from 'lodash-es';
import VueDOMPurifyHTML from 'vue-dompurify-html';
import TextInput from '@/components/TextInput';

Vue.use(VueDOMPurifyHTML);

export default {
  components: {
    TextInput,
  },
  data() {
    return {
      place: {},
      input: '',
      result: [],
      currentSearchItem: 0,
      justCleared: false,
      geocoder: null,
      sessionToken: null,
      autocompleteService: null,
    };
  },

  watch: {
    input: debounce(function (value) {
      if (this.justCleared) {
        this.justCleared = false;
        return;
      }

      /**
       * Limit the search to 2 characters
       */
      if (value.length < 2) {
        this.result = [];
        return;
      }

      /**
       * Start fetching the results
       */
      this.autocompleteService.getPlacePredictions(
        {
          input: value,
          sessionToken: this.sessionToken,
          language: 'ar',
          componentRestrictions: { country: 'eg' },
        },
        (predictions, status) => {
          if (status !== 'OK') {
            return;
          }

          this.result = predictions;
        }
      );
    }, 400),
  },

  mounted() {
    try {
      this.geocoder = new window.google.maps.Geocoder();
      this.sessionToken = new window.google.maps.places.AutocompleteSessionToken();
      this.autocompleteService = new window.google.maps.places.AutocompleteService();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  },

  methods: {
    /**
     * Handle on item selected
     */
    handleOnItemSelect(index) {
      this.input = this.result[index]?.description;

      this.geocoder.geocode(
        {
          address: this.result[index]?.description,
        },
        (results, status) => {
          if (status === window.google.maps.GeocoderStatus.OK) {
            /**
             * Get latitude and longitude from the first result
             */
            const latitude = results[0].geometry.location.lat();
            const longitude = results[0].geometry.location.lng();

            this.$emit('input', {
              ...this.result[index],
              geometry: {
                location: {
                  lat: () => latitude,
                  lng: () => longitude,
                },
              },
            });
          } else {
            // eslint-disable-next-line no-console
            console.log('Geocoding failed: ' + status);
          }

          this.result = [];
          this.justCleared = true;
          this.currentSearchItem = 0;
        }
      );
    },

    /**
     * Handle on key up
     */
    handleKeyup(e) {
      /**
       * Handle arrow up
       */
      if (e.key === 'ArrowUp') {
        if (this.currentSearchItem > 0) {
          this.currentSearchItem--;
        }
      }

      /**
       * Handle arrow down
       */
      if (e.key === 'ArrowDown') {
        if (this.currentSearchItem < this.result.length - 1) {
          this.currentSearchItem++;
        }
      }

      /**
       * Handle enter key
       */
      if (e.key === 'Enter') {
        this.handleOnItemSelect(this.currentSearchItem);
      }
    },

    /**
     * Highlight text
     */
    highlightText(text, input) {
      const regex = new RegExp(input, 'gi');
      return text.replace(regex, match => `<strong>${match}</strong>`);
    },

    // On reset
    reset() {
      this.input = '';
      this.place = {};
      this.result = {};
    },
  },
};
</script>

<style lang="postcss" scoped>
.SearchInput {
  @apply mb-0;
}
</style>
